import React from 'react';
import styled from 'styled-components';
import { FaFacebookSquare } from '@react-icons/all-files/fa/FaFacebookSquare';
import { FaTwitterSquare } from '@react-icons/all-files/fa/FaTwitterSquare';
import { FaEnvelopeSquare } from '@react-icons/all-files/fa/FaEnvelopeSquare';

const SocialShareStyled = styled.div`
  list-style: none;
  margin: 0;
  padding: 1rem 0 0 0;
  font-family: RobotoCondensedRegular;
  text-transform: uppercase;
  border-top: 1px solid var(--border);
  line-height: 3rem;
  display: flex;
  ul {
    list-style: none;
    padding: 0;
    margin: 0 0 0 auto;
    display: flex;
    gap: 0.5rem;
  }
  li {
    border: none !important;
    display: inline-block !important;
  }
  a {
    font-family: RobotoCondensedRegular !important;
    font-weight: normal !important;
    font-size: 3rem;
    line-height: 1rem;
    text-transform: capitalize !important;
    &:hover {
      color: var(--watermelon);
    }
  }
  a svg {
    vertical-align: middle;
    fill: var(--watermelon);
    transition: var(--speed);
    &:hover {
      fill: var(--textColor);
    }
  }
`;

export default function SocialShare(props) {
  return (
    <SocialShareStyled>
      Share
      <ul>
        <li>
          <a
            href={`http://www.facebook.com/sharer/sharer.php?u=https://bontrowing.com${props.url}&title=${props.title}`}
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebookSquare />
          </a>
        </li>
        <li>
          <a
            href={`http://twitter.com/intent/tweet?original_referer=https://bontrowing.com${props.url}&text=${props.title}&url=https://bontrowing.com${props.url}`}
            target="_blank"
            rel="noreferrer"
          >
            <FaTwitterSquare />
          </a>
        </li>
        <li>
          <a
            href={`mailto:?subject=${props.title}&body=Check out this article on Bont Rowing https://bontrowing.com${props.url}`}
            target="_blank"
            rel="noreferrer"
          >
            <FaEnvelopeSquare />
          </a>
        </li>
      </ul>
    </SocialShareStyled>
  );
}
