import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import BlockContent from '@sanity/block-content-to-react';
import SocialShare from '../components/SocialShare';
import SEO from '../components/SEO';

const NewsStyled = styled.article`
  max-width: calc(var(--wrapper) * 0.5);
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  @media (max-width: 1023px) {
    padding: 1rem;
  }
  .article-header {
    display: grid;
    grid-template-columns: 2fr 3fr;
    gap: 2rem;
    margin: 2rem 0;
    span {
      text-transform: uppercase;
    }
    @media (max-width: 1023px) {
      grid-template-columns: 1fr;
    }
  }
  .article-header--heading {
    border-top: 1px solid var(--border);
    border-bottom: 1px solid var(--border);
    padding: 2rem 0 1rem;
  }
  @media (max-width: 1023px) {
    picture {
      order: 1;
    }
  }
  picture img {
    border-radius: var(--borderRadius);
    overflow: hidden;
  }
  h1.h3 {
    font-size: 3.25rem;
    line-height: 1;
    text-transform: normal;
    padding-top: 2rem;
    padding-bottom: 4rem;
  }
`;

const serializers = {
  types: {
    block: (props) => {
      const { style = 'normal' } = props.node;

      if (/^h\d/.test(style)) {
        const level = style.replace(/[^\d]/g, '');
        return React.createElement(
          style,
          { className: `heading-${level}` },
          props.children
        );
      }

      if (style === 'blockquote' || style === 'quote') {
        return <blockquote>{props.children}</blockquote>;
      }

      // Fall back to default handling
      return BlockContent.defaultSerializers.types.block(props);
    },
    code: (props) =>
      console.log('code block', props) || (
        <pre data-language={props.node.language}>
          <code>{props.node.code}</code>
        </pre>
      ),
  },
  list: (props) =>
    console.log('list', props) ||
    (props.type === 'bullet' ? (
      <ul>{props.children}</ul>
    ) : (
      <ol>{props.children}</ol>
    )),
  listItem: (props) =>
    console.log('list', props) ||
    (props.type === 'bullet' ? (
      <li>{props.children}</li>
    ) : (
      <li>{props.children}</li>
    )),
  marks: {
    strong: (props) =>
      console.log('strong', props) || <strong>{props.children}</strong>,
    em: (props) => console.log('em', props) || <em>{props.children}</em>,
    code: (props) =>
      console.log('code', props) || <code>{props.children}</code>,
  },
};

export default function SingleNewsPage({ data }) {
  const { news } = data;
  console.log(news);
  return (
    <>
      <SEO title={news.title} image={news.featuredImage.asset.fluid.src} />
      <NewsStyled>
        <div className="article-header">
          <div className="article-header--heading">
            <span>
              {news.category} - {news._createdAt}
            </span>

            <h1 className="h3">{news.title}</h1>
            <SocialShare
              url={`/news/${news.slug.current}`}
              title={news.title}
            />
          </div>
          <Img
            fluid={news.featuredImage.asset.fluid}
            alt={news.title}
            className="product-image"
            imgStyle={{ objectFit: 'cover' }}
          />
        </div>
        <BlockContent
          blocks={news._rawContent}
          projectId="ji3xh6f0"
          dataset="production"
          serializers={serializers}
          imageOptions={{
            fit: 'max',
          }}
        />
      </NewsStyled>
    </>
  );
}

export const query = graphql`
  query ($slug: String!) {
    news: sanityNews(slug: { current: { eq: $slug } }) {
      _rawContent
      title
      slug {
        current
      }
      featuredImage {
        asset {
          fluid(maxWidth: 1000) {
            ...GatsbySanityImageFluid
          }
        }
      }
      category
      _createdAt(formatString: "LL")
    }
  }
`;
